a<template>
  <div class="accountset enterpriselist">
    <div class="jobExpect infos">
      <div style="margin-top:10px;">
        <!-- 用于循环数据 -->
        <el-table :data="jobExpectList" border style="width: 100%;margin-top:10px;">
          <el-table-column prop="workCategory" label="求职类型" align="center"></el-table-column>
          <el-table-column prop="expectJobName" label="职位类别" align="center"></el-table-column>
          <!-- <el-table-column prop="expectPositionName" label="期望行业名称"></el-table-column> -->
          <el-table-column prop="expectSalary" label="期望薪资"></el-table-column> 
           <el-table-column prop="policitalStatus" label="匹配职位数量" width=180 align="center" fixed="right">
            <template slot-scope="scope">
              <div class="operation">
                <router-link :to="{path:'/recruit',query:{jobid:scope.row.expectJobs,citycode:scope.row.workArea.cityCode}}" >{{scope.row.fitJobCount}}</router-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="policitalStatus" label="操作" width=180 align="center" fixed="right">
            <template slot-scope="scope">
              <div class="operation">
                <el-button type="primary" class="infoBtn" size="small" plain @click="showjobExpect_edit(scope.$index)">
                  修改
                </el-button>
                <el-button type="danger" size="small" slot="reference" plain @click="showjobExpect_Delete(scope.row)">删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 添加学历 -->
      <div class="commonaddbtn" @click="showjobExpect_add">
        <i class="resumeicon"></i>添加求职期望
      </div>
    </div>
    <div class="submitbutton">
      <el-button type="primary" @click="onsubmit" :disabled="isEffective">提交审核</el-button>
    </div>
    <el-dialog :visible.sync="jobExpecteriencedialogVisible" :close-on-click-modal="false" top="3vh" width="35%"
      title="求职期望">
      <el-form label-width="83px" :model="jobExpectForm" label-position="left" ref="jobExpecterienceRef"
        :rules="jobExpecterienceRules">
        <el-form-item label="求职类型:" prop="companyName">
          <div>
            <el-radio v-model="jobExpectForm.workCategoryCode" label="0" border>专职</el-radio>
            <el-radio v-model="jobExpectForm.workCategoryCode" label="1" border>兼职</el-radio>
          </div>
        </el-form-item>
        <el-form-item class="formL" label="职位类别" prop="expectJobs">
          <el-cascader v-model="jobExpectForm.expectJobs" :options="expectJobsOpt" style="width: 200px"
            @change="getCascader">
          </el-cascader>
        </el-form-item>
        <el-form-item class="forma" label="工作地点" prop="workArea">
          <el-cascader v-model="jobExpectForm.area" :options="cityList" style="width: 200px" @change="getworkArea">
          </el-cascader>
        </el-form-item>
        <el-form-item class="formL" label="期望薪资">
          <el-select v-model="jobExpectForm.expectSalaryCode" placeholder="请选择" style="width: 200px"
            @change="getexpectSalary">
            <el-option v-for="(item, index) in expectSalaryList" :key="index" :label="item.name" :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="jobExpecterienceEdit(item, index)">完成</el-button>
          <el-button @click="jobExpecterienceRes1(index)">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import { candidate } from '@/api/resumeview';
import { professioncertlist } from '@/api/user';
import { usergetcurruserinfo } from '@/api/user';
import {
  job,
  jobDelete,
  education,
  educationDelete,
  projectAdd,
  projectDelete,
  familyMember,
  familyMemberDelete,
  addorupdaterewardexperience,
  delrewardexperience,
} from '@/api/resumeperfect';
// import { businessDelete } from '@/api/store';
import {
  ExpectJobs,
  ExpectJobs1,
  postResumes,
  phone,
  addorupdatejobexpect,
  deljobexpect
} from '@/api/resume';
import { candidateId } from '@/api/jobwant';
import { cityjson } from '@/utils/cityjson.js';
export default {
  name: 'newResumeperfect',
  data() {
    return {
      expectJobsOpt: [],
      isEffective: false,
      familydialogVisible: false,
      schooldialogVisible: false,
      jobExpecteriencedialogVisible: false,
      rewarddialogVisible: false,
      userinfo: null,
      jobExpectList: [],
      activeName: '1',
      jobExpectShow: false,
      jobExpecterienceRules: {
        JobTitle: [{ required: true, message: '不能为空', trigger: 'blur' }],
        corporateName: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
      },
      jobExpectForm: {
        area:[],
        candidateId: 0,
        expectJobs: 0,
        expectPosition: 0,
        workArea: {
          province: '',
          provinceCode: 0,
          city: '',
          cityCode: 0,
          county: '',
          countyCode: 0,
          longitude: '',
          latitude: '',
        },
        expectSalary: '',
        expectSalaryCode: '',
        workCategory: '专职',
        workCategoryCode: '0',
        id: 0,
      },
      cityList: cityjson,
      candidateId: 0,
      // 期望薪资的数据
      expectSalaryList: [],
    };
  },
  mounted() {
    this.onusergetcurruserinfo();
    this.getCandidateId();
    this.getExpectJobs();
    this.getCandidatefilter();
  },
  methods: {
 
    getCandidateId() {
      candidateId().then((res) => {
        if (res.data.result !== null && res.data.result > 0) {
          this.candidateId = res.data.result;
          this.getPerfectList();
        }
      });
    },
    async getPerfectList() {
      const { data: res } = await candidate(this.candidateId);
      if (res.code == 1) {
        this.id = res.result.id;
        this.jobExpectList = res.result.jobExpectList;
      } else {
        return this.$message.error('获取修改页面数据失败！');
      }
    },
    // 求职过滤条件
    async getCandidatefilter() {
      const res = await ExpectJobs();
      if (res.data.code == 1) {
        let expectSalaryList = res.data.result.sals;
        this.expectSalaryList = expectSalaryList.slice(
          1,
          expectSalaryList.length - 1
        );
        // let educations = res.result.educations;
        let educations = res.data.result.edus;
        this.educations = educations.slice(1, educations.length - 1);
        this.sexList = res.data.result.sexs;
      } else {
        this.$message.error('过滤条件获取失败！');
      }
    },
    // 期望薪资事件
    getexpectSalary(e) {
      this.getLoop(e, this.expectSalaryList);
      this.jobExpectForm.expectSalary = this.place;
      this.jobExpectForm.expectSalaryCode = e;
    },
    async getworkArea(e) {
      this.getLoop(e[0], this.cityList);
      this.jobExpectForm.workArea.provinceCode = e[0];
      this.jobExpectForm.workArea.province = this.place;

      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.jobExpectForm.workArea.cityCode = e[1];
      this.jobExpectForm.workArea.city = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.jobExpectForm.workArea.countyCode = e[2];
      this.jobExpectForm.workArea.county = this.place2;
      console.log(this.jobExpectForm.workArea)

    },
    // 循环地点名称事件
    getLoop(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place = item.itemText;
          } else if (item.code == code) {
            this.place = item.name;
          } else if (item.value == code) {
            this.place = item.label;
          } else {
            return;
          }
        });
      }
    },
    getLoop1(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place1 = item.itemText;
          } else if (item.code == code) {
            this.place1 = item.name;
          } else if (item.value == code) {
            this.place1 = item.label;
          } else {
            return;
          }
        });
      }
    },
    getLoop2(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place2 = item.itemText;
          } else if (item.code == code) {
            this.place2 = item.name;
          } else if (item.value == code) {
            this.place2 = item.label;
          } else {
            return;
          }
        });
      }
    },
    async getExpectJobs() {
      const { data: res } = await ExpectJobs();
      let Jobs = res.result.job;
      let newarr = [];
      this.handleData1(Jobs, newarr);
      this.expectJobsOpt = newarr;
    },
    handleData1(data, newarr) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        if (data[i].children && data[i].children.length > 0) {
          newarr.push({ value: data[i].id, label: data[i].name, children: [] });
          this.handleData(data[i].children, newarr[i].children);
        } else {
          newarr.push({ value: data[i].id, label: data[i].name });
        }
      }
      return newarr;
    },
    // 职业类别级联选择器事件
    getCascader(e) {
      for (let i = 0; i < e.length; i++) {
        this.jobExpectForm.expectJobs = e[e.length - 1];
      }
    },
    onsubmit() {
      this.$message.success('提交成功!');
    },
    onusergetcurruserinfo() {
      usergetcurruserinfo().then((res) => {
        if (res.data.code == 1) {
          this.userinfo = res.data.result;
        }
      });
    },
    // 修改求职期望
    showjobExpect_edit(index) {
      this.jid = index;
      this.jobExpecteriencedialogVisible = true;
      this.jobExpectForm.expectJobs = this.jobExpectList[index].expectJobs;
      this.jobExpectForm.id = this.jobExpectList[index].id;
      this.jobExpectForm.expectPosition = this.jobExpectList[index].expectPosition;
      this.jobExpectForm.expectSalary = this.jobExpectList[index].expectSalary;
      this.jobExpectForm.expectSalaryCode = this.jobExpectList[index].expectSalaryCode;
      this.jobExpectForm.workCategory = this.jobExpectList[index].workCategory;
      this.jobExpectForm.workCategoryCode = this.jobExpectList[index].workCategoryCode;

      this.jobExpectForm.workArea = this.jobExpectList[index].workArea;

    //  [""+item.provinceId,""+item.cityId, ""+item.countyId]

      //this.jobExpectForm.workArea = this.jobExpectList[index].workArea;
      let area=[];
      area.push(this.jobExpectList[index].workArea.provinceCode);
      area.push(this.jobExpectList[index].workArea.cityCode);
      area.push(this.jobExpectList[index].workArea.countyCode);

      this.jobExpectForm.area=area;
      console.log(this.jobExpectForm.area)
    },
    // 添加求职期望
    showjobExpect_add() {
      this.jobExpecteriencedialogVisible = false;
      this.jobExpectForm.expectJobs = '';
      this.jobExpectForm.expectPosition = 0;
      this.jobExpectForm.workCategory = '专职';
      this.jobExpectForm.workCategoryCode = '0';
      this.jobExpectForm.workArea = {
          province: '',
          provinceCode: 0,
          city: '',
          cityCode: 0,
          county: '',
          countyCode: 0,
          longitude: '',
          latitude: '',
        };
      this.jobExpectForm.id = 0;
      this.jobExpecteriencedialogVisible = true;
    },
    // 求职期望删除
    async showjobExpect_Delete(item) {
      let id = item.id;
     
      const { data: res } = await deljobexpect(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
                  this.getPerfectList();
      } else {
        return this.$message.error('删除失败！');
      }
    },
    // 求职期望表单按钮添加事件
    async jobExpecterienceAdd() {
      let param = {
        candidateId: 0,
        expectJobs: 0,
        expectPosition: 0,
        workArea: {
          province: '',
          provinceCode: 0,
          city: '',
          cityCode: 0,
          county: '',
          countyCode: 0,
          longitude: '',
          latitude: '',
        },
        expectSalary: '',
        expectSalaryCode: '',
        workCategory: '专职',
        workCategoryCode: '0',
        id: 0,
      };
      param.expectJobs = this.jobExpectForm.expectJobs;
      param.expectPosition = this.jobExpectForm.expectPosition;
      param.expectSalary = this.jobExpectForm.expectSalary;
      param.expectSalaryCode = this.jobExpectForm.expectSalaryCode;
      param.workCategoryCode = this.jobExpectForm.workCategoryCode;
      param.workCategory = this.jobExpectForm.workCategoryCode==1?'兼职':'专职';

      param.workArea.province = this.jobExpectForm.workArea.province;
      param.workArea.provinceCode = this.jobExpectForm.workArea.provinceCode;
      param.workArea.city = this.jobExpectForm.workArea.city;
      param.workArea.cityCode = this.jobExpectForm.workArea.cityCode;
      param.workArea.county = this.jobExpectForm.workArea.county;
      param.workArea.countyCode = this.jobExpectForm.workArea.countyCode;

      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await addorupdatejobexpect(param);
      if (res.code == 1) {
          this.getPerfectList();
        this.$message.success('添加求职期望成功！');
        this.jobExpecteriencedialogVisible = false;
      } else {
        return this.$message.error('添加求职期望失败！');
      }
    },
    // 求职期望表单按钮修改事件
    async jobExpecterienceEdit(item, index) {
      if (this.jobExpectForm.id == 0) {
        this.jobExpecterienceAdd();
      } else {
        let param = {
          candidateId: 0,
          expectJobs: 0,
          expectPosition: 0,
          workArea: {
            province: '',
            provinceCode: '',
            city: '',
            cityCode: '',
            county: '',
            countyCode: '',
            longitude: '',
            latitude: '',
          },
          expectSalary: '',
          expectSalaryCode: '',
          workCategory: '',
          workCategoryCode: '',
          id: 0,
        };
        
        console.log(this.jobExpectForm)
        param.expectJobs = this.jobExpectForm.expectJobs;
        param.expectPosition = this.jobExpectForm.expectPosition;
        param.expectSalary = this.jobExpectForm.expectSalary;
        param.expectSalaryCode = this.jobExpectForm.expectSalaryCode;
        param.workCategory = this.jobExpectForm.workCategoryCode==1?'兼职':'专职';
        param.workCategoryCode = this.jobExpectForm.workCategoryCode;
        param.workArea.province = this.jobExpectForm.workArea.province;
      param.workArea.provinceCode = this.jobExpectForm.workArea.provinceCode;
      param.workArea.city = this.jobExpectForm.workArea.city;
      param.workArea.cityCode = this.jobExpectForm.workArea.cityCode;
      param.workArea.county = this.jobExpectForm.workArea.county;
      param.workArea.countyCode = this.jobExpectForm.workArea.countyCode;

       
        param.id = this.jobExpectForm.id;
        let candidateId = this.candidateId;
        param.candidateId = candidateId;
        const { data: res } = await addorupdatejobexpect(param);
        if (res.code == 1) {
          this.$message.success('修改求职期望成功！');
          let candidateId = this.candidateId;
          this.jobExpecteriencedialogVisible = false;
          this.getPerfectList();

          this.jobExpectForm.expectJobs = '';
          this.jobExpectForm.expectPosition = 0;
          this.jobExpectForm.workCategory = '';
          this.jobExpectForm.workCategoryCode = '';
          this.jobExpectForm.workArea = {
          province: '',
          provinceCode: 0,
          city: '',
          cityCode: 0,
          county: '',
          countyCode: 0,
          longitude: '',
          latitude: '',
        }
        } else {
          return this.$message.error('修改求职期望失败！');
        }
      }
    },
    // 求职期望表单按钮取消事件
    jobExpecterienceRes1(index) {
      this.jobExpectForm.positionName = '';
      this.jobExpectForm.salary = 0;
      this.jobExpectForm.companyName = '';
      this.jobExpectForm.workEndDate = '';
      this.jobExpectForm.workStartDate = '';
      this.jobExpectForm.jobContent = '';
      this.jobExpectForm.achievement = '';
      //this.jid = index - 100;
      this.jobExpecteriencedialogVisible = false;
    },
    jobExpecterienceRes2() {
      this.jobExpectForm.positionName = '';
      this.jobExpectForm.salary = 0;
      this.jobExpectForm.companyName = '';
      this.jobExpectForm.workEndDate = [];
      this.jobExpectForm.jobContent = '';
      this.jobExpectForm.achievement = '';
      this.jobExpectShowAdd = false;
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: resumeicon;
  src: url(../../assets/fonts/resumeicon.eot);
  src: url(../../assets/fonts/resumeicon.eot?#iefix) format('embedded-opentype'),
    url(../../assets/fonts/resumeicon.woff) format('woff'),
    url(../../assets/fonts/resumeicon.ttf) format('truetype'),
    url(../../assets/fonts/resumeicon.svg) format('svg');
}
/* 公共样式开始 */
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 880px;
  margin-right: 60px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .titleL {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  font-weight: 400;
  border-bottom: 1px solid #e0e0e0;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}


.accountset >>> form.elform {
  width: 460px;
}
/* 公共样式结束 */
.functionUnit {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.annex {
  width: 30%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  padding: 16px 18px;
  box-sizing: border-box;
  margin-left: 10px;
}
.annex .upload-title {
  height: 21px;
  line-height: 21px;
  font-size: 16px;
  color: #666;
  text-align: left;
}
.annex .upload-btn {
  width: 174px;
  height: 40px;
  line-height: 40px;
  background: #ff552e;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  margin: 14px 0 10px 0;
  cursor: pointer;
  text-align: center;
}
.annex .upload-explain {
  line-height: 16px;
  font-size: 12px;
  color: #aaa;
}

.infos {
  position: relative;
  overflow: hidden;
}

.infos .title h2 {
  display: inline-block;
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 20px;
  color: #333;
  line-height: 32px;
  font-weight: 400;
  position: relative;
}
.infos .title.intotitle h2::before {
  content: '';
  position: absolute;
  width: 3px;
  height: 19px;
  border-radius: 2px;
  background-color: #268eff;
  left: -8px;
  top: 8px;
}

.infos .title h4 {
  display: inline-block;
  margin-left: 5px;
  color: #666;
  font-weight: 400;
}
.infos i {
  margin-right: 4px;
  font-size: 14px;
  color: #999;
  font-family: resumeicon !important;
  font-size: 12px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.infos .btn {
  margin-right: 70px;
}
.infos .btn div {
  margin-right: 10px;
  margin-top: 15px;
  float: right;
  width: 56px;
  height: 24px;
  background: #ff552d;
  border-radius: 2px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}
.infos .btn i {
  color: #fff !important;
}
.infos .commonaddbtn {
  margin: 0 auto;
  padding: 0 5px;
  width: 140px;
  height: 38px;
  background: #f8f9fb;
  border-radius: 2px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #666;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  margin-top: 20px;
}
.infos .commonaddbtn i {
  font-size: 14px;
  color: #ccc;
  padding-right: 5px;
}

.basic {
  border-bottom: 1px solid #eee;
  display: flex;
}
.basic .infos {
  width: 90%;
}
.basic .infos .title h2 {
  font-size: 24px;
}
.basic .infos ul {
  margin-top: 14px;
}
.basic .infos li {
  /* float: left; */
  height: 32px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #666;
  text-align: left;
}
.basic .head {
  width: 110px;
  height: 110px;
  position: relative;
  border-radius: 50%;
  margin-left: -25px;
  margin-top: 33px;
}
.basic .head img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.required {
  font-family: Tahoma;
  font-size: 14px;
  color: red;
  line-height: 14px;
}
.edit {
  padding: 0px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}
.edit .title {
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 20px;
  color: #333;
  line-height: 28px;
}
.edit .el-form {
  margin-top: 30px;
}
.edit .el-form .controly {
  width: 200px;
}
.edit .el-form .control {
  width: 300px;
}
.edit .el-form .controlx {
  width: 483px;
}
.edit .el-form .controls {
  width: 86%;
}
.controls .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.edit .el-button {
  min-width: 80px;
}
.edit .el-button--primary {
  background-color: #ff552d;
  border: 1px solid #ff552d;
}

.jobobjective .content {
  margin-top: 50px;
}

.jobobjective .content span {
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #333;
  text-align: left;
}
.jobobjective .content i {
  font-size: 12px;
  color: #cdcdcd;
  padding: 0 10px;
}

.jobExpect .content {
  padding-top: 14px;
}
.jobExpect .content dt {
  float: left;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  vertical-align: top;
  width: 80px;
}
.jobExpect .content dd {
  float: left;
  font-size: 14px;
  color: #666;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
}
.jobExpect .content .com {
  padding-top: 12px;
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-weight: 700;
  font-size: 16px;
  color: #333;
  line-height: 28px;
  width: auto;
}
.jobExpect .content dd.com {
  font-size: 14px;
  color: #999;
  line-height: 20px;
  margin: 6px 0 0 5px;
}
.school {
  margin-top: 35px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.schools {
  margin-top: 40px;
  width: 83%;
}
.worke {
  margin-top: 35px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.Project {
  margin-top: 35px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.el-main {
  border: 1px solid #eee;
  border-bottom-color: transparent;
  padding: 0;
}
.aside {
  overflow: auto;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  top: 0;
}
.basic .head >>> .el-upload--picture-card {
  background-color: #fbfdff;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  vertical-align: top;
}
.basic .head >>> .el-upload__tip {
  font-size: 12px;
  color: #606266;
  width: 100px;
  margin-left: 26px;
}
.avatar {
  width: 100%;
  height: 100%;
}
.infoBtn {
  margin-left: 10px;
}
.submitbutton {
  text-align: center;
  padding: 20px 0;
}
</style>